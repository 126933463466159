<template></template>
<script>
import { mapState } from "vuex";
import { action_items } from "@/definition.js";
import BaseFunction from "../BaseFunction.js";
export default {
  props: {
    show: false,
    action: action_items.view,
    item: {},
    total_item: {},
    title: null,
    dialogTotalItem: {},
    dialogResultItem: {},
    max_width: {
      type: String,
      default: "59%"
    },
    height: {
      type: String,
      default: "60%"
    },
  },
  data: vm => ({
    topic: "",
    action_items: action_items,
    disableSave: false,
    rules: {
      url: [
        value => {
          let result = true;
          if (value && value.length > 0) {
            const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            result = pattern.test(value);
          }
          if (result) {
            vm.disableSave = false;
            return true;
          }
          vm.disableSave = true;
          return vm.$i18n.t("invalid-url");
        }
      ],
      password: [
        value => {
          if (value && value.length < 6) {
            vm.disableSave = true;
            return vm.$i18n.t("invalid-password");
          }
          vm.disableSave = false;
          return true;
        }
      ]
    }
  }),
  computed: {
    ...mapState(["role","language"]),
    formTitle() {
      if (!this.$common_util.isEmpty(this.title)) {
        return this.title;
      }
      let title = "";
      switch (this.action) {
        case action_items.create:
          title = this.$t("create");
          break;
        case action_items.view:
          title = this.$t("view");
          break;
        case action_items.edit:
          title = this.$t("edit");
          break;
      }
      return title + " " + this.topic;
    },
    isTraditional(){
      return (this.$i18n.locale=='zhHant')?true:false
    },
    isSimplified(){
      return (this.$i18n.locale=='zhHans')?true:false
    },
    isAdmin(){
      return (this.role===0)?true:false
    },
    nameField(){
      if (this.$i18n.locale === 'zhHant'){
        return 'name_tc'
      } else {
        return 'name_sc'
      }
    },
    nameFieldFilter(){
      return this.nameField + '__startswith'
    },
  },
  methods: {
    close() {
      // this.item = {};
      this.$emit("onClose");
    },
    save() {
        console.log('dialog save')
      let editedItem = this._.cloneDeep(this.item);
      this.$emit("onSave", editedItem);
    },
    getHanText: BaseFunction.getHanText,
    arraySum: BaseFunction.arraySum,
    fixedFloat: BaseFunction.fixedFloat,
  }
};
</script>
<style>
.total-row td {
  font-weight: bold;
}
</style>
