<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.name"
                :label="$t('container-box.extra-type')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="item.unit"
                :items="deliver_fee_unit_items"
                :item-text="item => $t(item.text)"
                :label="$t('container-box.extra-unit')"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.unit_fee"
                :label="$t('container-box.extra-fee')"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>suject</v-col>
          </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import { deliver_fee_unit_items } from "@/definition.js"
export default {
  data: (vm) => ({
    topic: vm.$i18n.t("commodity.additional-fee"),
    deliver_fee_unit_items: deliver_fee_unit_items,
  }),
  computed: {
    allowSave() {
      return false;
    },
  },
  mixins: [BaseDialog],
}
</script>