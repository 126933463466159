<template>
  <v-container fluid>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <AdditionalFeeDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></AdditionalFeeDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('commodity.additional-fee-list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.unit="{ item }">
          {{ item.unit | getText(deliver_fee_unit_items) | Translate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import AdditionalFeeDialog from "../dialogs/AdditionalFeeDialog";
import { action_items, deliver_fee_unit_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/commodity/additional-fee/",
    headersMap: {
      name:{
        sortable: true,
        text: vm.$i18n.t("container-box.extra-type"),
        value: "name",
      },
      unit:{
        sortable: true,
        text: vm.$i18n.t("container-box.extra-unit"),
        value: "unit",
      },
      unit_fee:{
        sortable: true,
        text: vm.$i18n.t("container-box.extra-fee"),
        value: "unit_fee",
      },
      actions:{
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    },
    tcHeaders: ['name', 'unit', 'unit_fee', 'actions'],
    scHeaders: ['name', 'unit', 'unit_fee', 'actions'],
    action_items: action_items,
    deliver_fee_unit_items: deliver_fee_unit_items,
  }),
  mixins: [BaseTable],
  components: {
      AdditionalFeeDialog,
  },
  methods: {
    createItem(item) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.showDialog = true;
    },
    editItem(item) {
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      this.action = action_items.edit;
      this.showDialog = true;
    },
  },
}
</script>